import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid, Button, Badge } from "@material-ui/core";

/*Actions*/
import { setHeaderProps } from "../../Redux/Actions/_actionHeader";
import { openProduct } from "../../Redux/Actions/_actionProducts";
import { openLogin } from "../../Redux/Actions/_actionLogin";
import { setResetAll, setHome, catchHomeApi, openBarraSearch, setData } from "../../Redux/Actions/_actionHome";
import { setContactUs } from "../../Redux/Actions/_actionFooter";
import { openUs } from "../../Redux/Actions/_actionFooter";
import { openShoppingCar } from "../../Redux/Actions/_actionShoppingCar";
import { gustaOpen } from "../../Redux/Actions/_actionGussta";

/*Servicios */
import { getPreferencesGussta } from "../../Redux/Actions/services/getPreferencesGussta";
import { getCarrito } from "../../Redux/Actions/services/getCarrito";
import { getHomeCategories } from "../../Redux/Actions/services/getHomeCategories";

//Constantes
import { LOGIN_DATA_LS, ENCRYP_TYPE } from "../../Constants";
import { DencryptData } from "../../Tools/CryptoLocalStorage";
import { styles } from "./constants.js";

import LOGO from "../Home/img/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginBool: this.props.loginSuccessPayload,
      loginDataStorage: this.props.loginSuccessPayload,
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("loginBool")) {
      if (!this.props.openShopCar)
        this.props.getPreferencesGussta();
      this.setState({
        loginBool: localStorage.getItem("loginBool"),
        loginDataStorage: DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS),
      });
    }
  };

  handleHome = () => {
    if (window.location.origin + "/" !== window.location.href) {
      window.location.href = "/nemesis";
    }else{
      window.location.href = window.location.origin
    }
  };

  handleLogin = () => {
    this.props.openLogin(true);
  };

  handleCarrito = () => {
    this.props.getCarrito();
    this.props.gustaOpen(false);
    this.props.setContactUs(false);
  };

  handleGussta = () => {
    this.props.setContactUs(false);
    this.props.openUs(false);
    this.props.openShoppingCar(false);
    this.props.openProduct(false);
    this.props.gustaOpen(true);
    this.props.setHome(false);
    this.props.catchHomeApi(false);
  };

  handleSearch = () => {
    this.props.setData([]);
    this.props.openBarraSearch(this.props.openBarra ? false : true);
  }

  render() {
    const { classes } = this.props;

    return (
      <AppBar
        position="absolute"
        style={{ backgroundColor: this.props.headerColor ? this.props.headerColor : "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={4} md={2} style={{ margin: 'auto' }}>
              <Button
                color="inherit"
                onClick={this.handleHome}
              >
                <img src={LOGO} alt="logo" width="80px" />
              </Button>
            </Grid>
            <Grid item xs={8} md={10}>
              {this.state.loginBool || this.props.loginSuccessPayload ? (
                <div
                  className={classes.button}
                  onClick={this.handleCarrito}>
                  <Badge
                    badgeContent={
                      this.props.preferencesGussta
                        ? this.props.preferencesGussta.candidadCarrito : "0"
                    }
                    color="primary"
                  >
                    <i
                      style={{ color: this.props.iconsColor, fontSize: 22 }}
                      class="icon-guss_shop"
                    ></i>
                  </Badge>
                </div>
              ) : (<></>)}
              {this.state.loginBool || this.props.loginSuccessPayload ?
                <div
                  className={classes.button}
                  onClick={this.handleGussta}>
                  <i
                    style={{ color: this.props.iconsColor, fontSize: 22 }}
                    class="icon-guss_user"
                  ></i>
                  <div className={classes.namesHeader}>
                    {"Hola, "}
                    {this.props.loginData
                      ? this.props.loginData.usuario.nombre
                      : this.state.loginDataStorage.usuario.nombre}
                    &#10240;
                    {this.props.loginData
                      ? this.props.loginData.usuario.razonSocial
                      : this.state.loginDataStorage.usuario.razonSocial}
                  </div>
                </div>
                :
                <div
                  className={classes.button}
                  onClick={this.handleLogin}>
                  <i
                    style={{ color: this.props.iconsColor, fontSize: 22 }}
                    class="icon-guss_user"
                  ></i>
                </div>
              }
              {this.props.isMobile &&
                <div
                  className={classes.button}
                  onClick={this.handleSearch}>
                  <i
                    style={{ color: this.props.iconsColor, fontSize: 22 }}
                    class="icon-guss_search"
                  ></i>
                </div>
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const header = withStyles(styles, { withTheme: true })(Header);

function mapStateToProps(state) {
  return {
    iconsColor: state.headerProps.iconsColor,
    headerColor: state.headerProps.headerColor,
    loginSuccessPayload: state.loginReducer.loginSuccessPayload,
    loginData: state.loginReducer.loginData,
    preferencesGussta: state.gusstaReducer.preferencesGussta,
    isMobile: state.homeProps.isMobile,
    openBarra: state.homeProps.openBarra,
    openShopCar: state.purchase.openShoppingCar,
  };
}

export default connect(mapStateToProps, {
  setHeaderProps,
  openProduct,
  openLogin,
  setResetAll,
  setHome,
  setContactUs,
  openUs,
  openShoppingCar,
  getCarrito,
  gustaOpen,
  catchHomeApi,
  getHomeCategories,
  openBarraSearch,
  setData,
  getPreferencesGussta
})(header);
