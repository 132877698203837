import { makeStyles } from "@material-ui/core";
import backgroundImage from "./img/gussmuebles-landing-page.jpg"


export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
    },
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        '@media (min-width: 320px) and (max-width: 767px)': {
            backgroundImage: 'none'
        },
    },
    gridItem: {
        paddingTop: 90,
        paddingLeft: 50,
        paddingRight: 80,
        '@media (min-width: 320px) and (max-width: 767px)': {
            padding: 30,
            paddingTop: 10
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            padding: 30
        },
        '@media (min-width: 1920px)': {
            paddingTop: 170
        },
    },
    title: {
        marginTop: '28px',
        fontSize: '22px',
        lineHeight: '1.1'
    },
    titleAddress: {
        marginTop: '28px',
        fontSize: '17px',
        color: '#a4a4a5',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '16px',
        },
    },
    list: {
        marginLeft: '30px',
        listStyleType: 'disc',
        fontSize: '17px',
        color: '#a4a4a5',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '16px',
        },
    },
    listItem: {
        display: 'list-item',
        paddingLeft: '0',
        paddingBottom: '0'
    },
    button: {
        display: 'block',
        margin: 'auto',
        marginBottom: '10px',
        borderRadius: '50px',
        textTransform: 'uppercase',
        fontWeight: 500,
        padding: '5px 45px 5px 45px',
        '& a': {
            color: '#fff',
            textDecoration: 'none',
            '@media (min-width: 320px) and (max-width: 767px)': {
                fontSize: '15px'
            },
        },
        '@media (min-width: 320px) and (max-width: 767px)': {
            borderRadius: '25px',
            padding: '5px 15px 5px 15px',
        },
    },
    buttonGreen: {
        background: '#75ba26',
        borderColor: '#75ba26',
        '&:hover': {
            background: '#79c324',
            borderColor: '#79c324'
        }
    },
    iconsNetwork: {
        marginTop: '10px',
        marginRight: '10px',
        color: '#75ba26',
        fontSize: '35px'
    },
    divIcons: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            marginTop: '20px',
            textAlign: 'center'
        },
    }
}))