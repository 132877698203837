import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import Home from "../Components/Home";
import Products from "../Components/Products";
import Profile from "../Components/Profile";
import ContactUs from "../Components/Footer/ContactUs";
import Gussta from "../Components/Gussta";
import Offert from "../Components/Offert";
import Footer from "../Components/Footer";
import Unlocking from "../Components/Home/Login/Unlocking";
import Recovery from "../Components/Home/Login/Recovery";
import RenewLogin from "../Components/Home/Login/RenewLogin";
import PDF from "../Components/ShoppingCar/PDF";
import ShoppingCar from "../Components/ShoppingCar";
import US from "../Components/Footer/US";

import { THEME } from "../Constants";
// import Header from "../Components/Header";
import LandingPages from "../Components/Home/LandingPages";
const theme = THEME;

class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <ThemeProvider theme={theme}>
            {/* <Header/> */}
            <Route exact path="/">
              <LandingPages />
            </Route>
            <Route exact path="/nemesis">
              <Home />
            </Route>
            <Route exact path="/pdf">
              <PDF />
            </Route>
            <Route path="/search">
              <Products />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/contact">
              <ContactUs />
            </Route>
            <Route path="/us">
              <US />
            </Route>            
            <Route path="/gussta">
              <Gussta />
            </Route>
            <Route path="/renew">
              <RenewLogin />
            </Route>
            <Route path="/offert">
              <Offert />
            </Route>
            <Route path="/footer">
              <Footer />
            </Route>
            <Route path="/recovery">
              <Recovery />
            </Route>
            <Route path="/des">
              <Unlocking />
            </Route>
            <Route path="/nemesis/share">
              <Home share={true} />
            </Route>
            <Route path="/ShoppingCar">
              <ShoppingCar />
            </Route>
          </ThemeProvider>
        </Switch>
      </Router>
    );
  }
}

export default Routes;