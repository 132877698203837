import React from "react";
import { Grid, List, ListItem, Typography } from "@material-ui/core";
import LOGO from "../../Home/img/logo.png";
import { properties } from "../../../Constants/index.js";
import { useStyles } from "./constants.js";
import { Button } from "react-bootstrap";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';


function LandingPages() {

    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} sm={6} className={classes.background}></Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
                <img src={LOGO} alt="logo" width="180px" />
                <Typography variant="h2" className={classes.title}>{properties.landingPagesText.initialText}</Typography>
                <Typography variant="subtitle1" className={classes.titleAddress}>📌{properties.landingPagesText.addressText}</Typography>
                <Typography variant="subtitle1" className={classes.titleAddress}>{properties.landingPagesText.ourScheduleText}</Typography>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>{properties.landingPagesText.weekShedule}</ListItem>
                    <ListItem className={classes.listItem}>{properties.landingPagesText.weekendShedule}</ListItem>
                </List>
                <Button style={{ marginTop: '35px' }} className={classes.button}>
                    <a
                        href={properties.landingPagesText.linkGoogleMaps}
                        target="_blank"
                        rel="noreferrer"
                    >
                     {properties.landingPagesText.addressGoogleMapsText}
                </a>
                </Button>
                <Button className={[classes.button, classes.buttonGreen]}>
                <a
                        href={properties.landingPagesText.linkWhatsapp}
                        target="_blank"
                        rel="noreferrer"
                    >
                    {properties.landingPagesText.whatsappText}
                </a>
                </Button>
                <div className={classes.divIcons}>
                    <a
                        href={properties.networkLinks.facebook}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FacebookIcon className={classes.iconsNetwork} />
                    </a>

                    <a
                        href={properties.networkLinks.instagram}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <InstagramIcon className={classes.iconsNetwork} />
                    </a>
                </div>
            </Grid>
        </Grid>
    );
}
export default LandingPages;
