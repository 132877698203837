import React, { Component } from 'react';
import { connect } from 'react-redux';
import Magnifier from "react-magnifier";
import {
    Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Grid, Typography, IconButton, Divider, CardActionArea, Grow,
    Button, Chip, Paper, InputBase, Tooltip, Box
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withStyles } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ImagenesSliderSwiper from './Mobile';

/*Actions */
import { detailProduct, imagenSelected, activeStep } from '../../../Redux/Actions/_actionProducts';
import { getDetailProduct } from '../../../Redux/Actions/services/getDetailProduct';
import { postCreateQuestions } from '../../../Redux/Actions/services/postCreateQuestions';
import { setLoader } from '../../../Redux/Actions/_actionLoader';
import { postActualizarPreferencias } from '../../../Redux/Actions/services/postActualizarPreferencias';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { setAlert } from '../../../Redux/Actions/_actionAlerts';
import { openAddCarModal } from '../../../Redux/Actions/_actionShoppingCar';
import { searchCategories, loadingSearchMobile } from '../../../Redux/Actions/_actionHome';

//Constantes
import { LOGIN_DATA_LS, ENCRYP_TYPE, CATEGORY_LS, ART_LS } from '../../../Constants';
import { styles } from '../constants.js';
import { properties } from '../../../Constants';

/*Icons */
import LikeSi from "./img/Like lleno.svg";
import LikeNo from "./img/Like vacio.svg";
import SaveSi from "./img/Guardar azul.svg";
import SaveNo from "./img/Guardar vacio.svg";
// import useClipboard from 'react-hook-clipboard';


const labels = JSON.parse(localStorage.getItem("labels"))

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: true,
            animation1: true,
            activeStep: 0,
            name: '',
            desc: '',
            info: '',
            imgURL: '',
            price: 0,
            valorPromo: 0,
            offert: 0,
            product: [],
            Imagenes: [],
            similares: [],
            Ficha: [],
            loader: false,
            activeStepSimilares: 0,
            idArticulo: '',
            activeLike: false,
            activeSave: false,
            statusLike: 0,
            statusSave: 0,
            comment: '',
            activeBtnCar: false,
            captcha: undefined,
            share: false,
            url: '',
            coppied: false,
        }
        this.icons = this.icons.bind(this)
        this.captcha = React.createRef();
    }

    componentDidMount() {
        this.renderComponent();
    }

    renderComponent = () => {
        let arreglo = this.props.productDetails.Imagenes;
        let similares = this.props.productDetails.Articulos_Similares;
        let data2 = [...arreglo]
        let prueba = [];
        let pruebaSimilares = [];
        let similares2 = [...similares]
        const { Descripcion, Precio, Colores, Preferencias } = this.props.productDetails;

        Descripcion.forEach(des => {
            this.setState({
                name: des.descripcionCorta,
                desc: des.descripcion,
                idArticulo: des.idArticulo
            })

            if (localStorage.getItem('loginBool')) {
                this.setState({ activeBtnCar: true });
                if (Preferencias.length > 0) {
                    Preferencias.forEach(pref => {
                        if (pref.idArticulo === des.idArticulo) {
                            if (pref.idDescr === 'DESC-205') {
                                this.setState({
                                    activeLike: pref.idStatus === '1' ? true : false,
                                    statusLike: pref.idStatus
                                })
                            } else {
                                this.setState({
                                    activeSave: pref.idStatus === '1' ? true : false,
                                    statusSave: pref.idStatus
                                })

                            }
                        }
                    });
                }
            }
        })
        this.setState({
            price: Precio[0].precio,
            offert: Precio[0].precioEspecial,
        })

        if (data2.length > 0) {
            this.setState({
                imgURL: data2[0].imgURL,
                Imagenes: data2,
            })
        }

        pruebaSimilares = this.windowsResponsive(similares2);

        if (Colores.length > 1) {
            prueba = this.windowsResponsive([data2[0]]);
        } else {
            prueba = this.windowsResponsive(data2);
        }
        this.setState({
            product: prueba,
            similares: pruebaSimilares
        })

        //Se agregan estas líneas para usar el swiper en la versión mobile
        /* Swiper es un visualizador de imagenes que permite verlas deslizandose por ellas */
        this.props.imagenSelected(prueba);
        this.props.activeStep(0);
        //////////////////////////////////////////////////////////////////
    }

    windowsResponsive = (data) => {
        var product = [];
        if (window.innerWidth > 959) {
            while (data.length > 0) {
                product.push(data.splice(0, 4))
            }
        } else if (window.innerWidth < 959 && window.innerWidth > 780) {
            while (data.length > 0) {
                product.push(data.splice(0, 2))
            }
        } else if (window.innerWidth < 780) {
            while (data.length > 0) {
                product.push(data.splice(0, 4))
            }
        }
        return product;
    }

    handleClose = () => {
        this.props.detailProduct(false);
    }

    handleNext = (bool) => {
        if (bool) {
            this.setState({
                animation1: false,
                anima: true,
                direction: 'left',
            })
        } else {
            this.setState({
                animation: false,
                anima: true,
                direction: 'left',
            })

        }
        setTimeout(() => {
            if (bool) {
                this.setState({
                    animation1: true,
                    anima: false,
                    activeStepSimilares: this.state.activeStepSimilares + 1
                })
            } else {
                this.setState({
                    animation: true,
                    anima: false,
                    activeStep: this.state.activeStep + 1
                })
            }
        }, 300);
    };

    handleBack = (bool) => {
        if (bool) {
            this.setState({
                animation1: false,
                anima: true,
                direction: 'right',
            })
        } else {
            this.setState({
                animation: false,
                anima: true,
                direction: 'right',
            })

        }

        setTimeout(() => {
            if (bool) {
                this.setState({
                    animation1: true,
                    anima: false,
                    activeStepSimilares: this.state.activeStepSimilares - 1
                })
            } else {
                this.setState({
                    animation: true,
                    anima: false,
                    activeStep: this.state.activeStep - 1
                })
            }
        }, 300);
    };

    ColorsProduct = (color, img) => {
        const { Imagenes } = this.state;
        const { Colores } = this.props.productDetails;
        const ProductColor = [];
        let prueba = [];
        if (Colores.length > 1) {
            if (color) {
                Imagenes.forEach(img => {
                    if (img.cHexadecimal === color) {
                        ProductColor.push(img)
                    }
                })
                this.setState({
                    imgURL: ProductColor[0].imgURL
                })
                prueba = this.windowsResponsive(ProductColor);
            } else {
                Imagenes.forEach(img1 => {
                    if (img1.cUrl === img) {
                        ProductColor.push(img1)
                    }
                })
                this.setState({
                    imgURL: ProductColor[0].imgURL
                })
                prueba = this.windowsResponsive(ProductColor);
            }
            this.setState({
                product: prueba
            });

            //Se agregan estas líneas para usar el swiper en la versión mobile
            /* Swiper es un visualizador de imagenes que permite verlas deslizandose por ellas */
            this.props.loadingSearchMobile(true);
            this.props.imagenSelected(prueba);
            this.props.activeStep(0);
            //////////////////////////////////////////////////////////////////
        }
    }

    imgDetailSimilares = (id) => {
        this.setState({
            loader: true
        })
        this.props.setLoader(true);
        this.props.detailProduct(false);
        this.props.getDetailProduct(id);
    }

    imgDetail = (img) => {
        this.setState({
            imgURL: img
        })
    }

    icons = (type) => {
        const { idArticulo, activeLike, activeSave, statusLike, statusSave } = this.state;
        if (localStorage.getItem('loginBool')) {
            const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
            const { usuario } = loginDataStorage
            var idDescrip = '';
            var typeAction = 0;
            const carritoIsOpen = this.props.openShoppingCar;

            if (type === 0) {
                idDescrip = 'DESC-205';
                if (statusLike === '1' && activeLike === true) {
                    typeAction = 0;
                    this.setState({
                        activeLike: false,
                        statusLike: '0'
                    })
                } else {
                    typeAction = 1;
                    this.setState({
                        activeLike: true,
                        statusLike: '1'
                    })
                }
            } else {
                idDescrip = 'DESC-206';
                if (statusSave === '1' && activeSave === true) {
                    typeAction = 0;
                    this.setState({
                        activeSave: false,
                        statusSave: '0'
                    })
                } else {
                    typeAction = 1;
                    this.setState({
                        activeSave: true,
                        statusSave: '1'
                    })
                }
            }

            this.props.setLoader(true);

            this.props.postActualizarPreferencias({
                "idArticulo": idArticulo,
                "idUsuario": usuario.idUsuario,
                "idDescr": idDescrip,
                "tipoAccion": typeAction,
                "usuario": usuario.displayName
            }, carritoIsOpen);
        } else {
            this.isNotLoggedIn()
        }
    }

    questionsCreate = () => {
        const { idArticulo, comment } = this.state;
        if (localStorage.getItem('loginBool')) {
            const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
            const { usuario } = loginDataStorage;

            /* Validación Captcha */
            if (!this.captcha.current.getValue()) {
                this.setState({ captcha: false })
                return;
            }

            if (comment === '')
                return;

            this.props.postCreateQuestions({
                idEmpresa: properties.companyID,
                idArticulo: idArticulo,
                idUsuario: usuario.idUsuario,
                idComentarioPadre: null,
                mensaje: comment,
                visible: false
            });

            this.captcha.current.reset();
            this.setState({
                comment: '',
                captcha: undefined
            })
        }
    }

    handleInputChange = (event) => {
        this.setState({
            comment: event.target.value
        })
    }

    handleShare = () => {
        var idCategoria = DencryptData(ENCRYP_TYPE.STRING, CATEGORY_LS);
        var idArticulo = DencryptData(ENCRYP_TYPE.STRING, ART_LS);
        var url = window.location.protocol + '//' + window.location.host + '/?share/' + idCategoria + '?' + idArticulo;
        this.setState({
            share: true,
            url: url
        })
    }

    handleWhatsappShare = () => {
        const whatsappUrl = 'https://wa.me/' + properties.phonenumber;
        const textoWhatsapp = properties.textoWhatsappShare.replace(new RegExp(" ","g"), '%20');
        const urlTotalWhatsapp = whatsappUrl + '?text=' + textoWhatsapp;
        const url = this.state.url;
        const whatsappLink = urlTotalWhatsapp + url;
        window.open(whatsappLink, '_blank');
    }

    handleCoppy = (open) => {
        navigator.clipboard.writeText(this.state.url).then(() => {
            this.setState({ coppied: true })
        });
    }

    handleOpenAddCar = () => {
        let productDetails = this.props.productDetails;
        this.props.openAddCarModal(true, productDetails);
        this.props.detailProduct(false);
    }

    onChange = () => {
        if (this.captcha.current.getValue()) {
            this.setState({ captcha: true })
        }
    }

    isNotLoggedIn = () => {
        this.props.setAlert(true, "Debe iniciar sesión para realizar esta acción", properties.severities.warning, properties.props.error)
    }

    render() {
        const { classes, questionsPayload } = this.props;
        const { Colores, Categorias, Promo, FichaTecnica } = this.props.productDetails;
        const { name, desc, imgURL, price, product, similares, activeLike, activeSave, comment } = this.state;
        return (
            <>
                {this.props.isMobile ?
                    <Dialog fullScreen TransitionComponent={Transition} open={this.props.productDetails}>
                        <Grid container className={classes.rootMobile}>
                            {/* Iconos Me gusta, compartir y guardar */}
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={10} style={{ display: 'inline-flex' }}>
                                        <Box onClick={this.icons.bind(this, 0)} style={{ cursor: "pointer" }}>
                                            {activeLike
                                                ? <img alt="img" src={LikeSi} style={{ marginTop: 11, }} width={32} />
                                                : <img alt="img" src={LikeNo} style={{ marginTop: 11, color: "black" }} width={32} />
                                            }
                                        </Box>
                                        <IconButton onClick={this.handleShare}>
                                            <i style={{ fontSize: 20, color: properties.green }} class="icon-guss_share"></i>
                                        </IconButton>
                                        <Box onClick={this.icons.bind(this, 1)} style={{ cursor: "pointer" }}>
                                            {activeSave
                                                ? <img alt="img" src={SaveSi} style={{ marginTop: 12 }} width={30} />
                                                : <img alt="img" src={SaveNo} style={{ marginTop: 12, color: "black" }} width={30} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={this.handleClose}
                                            aria-label="close"
                                            style={{ width: '48px' }}>
                                            <i style={{ fontSize: 20, marginRight: -50 }} class="icon-guss_close"></i>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Imagen principal del artículo */}
                            <Grid item xs={12} className={classes.imagenPrincipalMobile}>
                                <ImagenesSliderSwiper />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.colorClass}>
                            <Grid item xs={12}>
                                {Colores.map(col => (
                                    <Grid xs={2}>
                                        <Grid container className={classes.color} >
                                            <CardActionArea style={{ height: 35, width: 35 }} onClick={this.ColorsProduct.bind(this, col.cHexadecimal, col.cUrl)}>
                                                {col.cUrl ?
                                                    <img className={classes.coloresDesign} src={col.cUrl} alt='img' />
                                                    :
                                                    <Grid className={classes.coloresDesign} style={{ backgroundColor: col.cHexadecimal }} />
                                                }
                                            </CardActionArea>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.precio}>
                            {/* Categorías */}
                            <Grid xs={12} style={{ display: 'inline-flex' }}>
                                {Categorias.map(cat => (
                                    <Chip label={cat.descripcion} className={classes.chipMobile} />
                                ))}
                            </Grid>
                            {/* Nombre del artículo */}
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 600, paddingTop: 10 }}>
                                    {name}
                                </Typography>
                            </Grid>
                            {/* Descripció del artículo */}
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ fontWeight: 500, paddingTop: 10 }}>
                                    {desc}
                                </Typography>
                            </Grid>
                            {/* Precio del artículo */}
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                {Promo.length === 0 ?
                                    <>
                                        <Typography style={{ fontSize: 29, fontWeight: 600 }}>$ {price}</Typography>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} >
                                            <Typography style={{ fontSize: 29, fontWeight: 600 }}>$ {Promo[0].valorPromo}</Typography>
                                            <Typography style={{ fontSize: 20, textDecoration: 'line-through' }}>$ {price}</Typography>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            {/* Botón agregar carrito */}
                            <Grid item xs={12}>
                                <Button className={classes.iconoMovil}
                                    startIcon={<i style={{ fontSize: 20, color: properties.white }} class="icon-guss_shop"></i>}
                                    onClick={() => {this.state.activeBtnCar ? this.handleOpenAddCar() : this.isNotLoggedIn()}}>
                                    <span style={{ color: properties.white }} >{labels.SHOP_MOBILE}</span>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.fichaClass}>
                            <Grid xs={12}>
                                <Typography variant='h5' style={{marginBottom: 5}}>Ficha técnica del producto</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {FichaTecnica.length > 0 ?
                                    FichaTecnica.map(resp => (
                                        <Typography variant='subtitle1' className={classes.margin}>{resp.descripcion}</Typography>
                                    ))
                                    :
                                    <Typography variant='subtitle1' className={classes.margin}>No posee información.</Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid className={classes.question}>
                            <Grid item xs={12} >
                                <Grid xs={12} className={classes.answer}>
                                    <Typography variant='h5'>Preguntas y respuestas</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid container className={classes.fondoProductAnswer} >
                                    <Grid xs={12} style={{ marginBottom: 10 }}>
                                        <Paper elevation={0}
                                            className={classes.root} >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="Ingrese una nueva pregunta"
                                                value={comment}
                                                type="text"
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Button disabled={localStorage.getItem('loginBool') ? false : true}
                                            variant="outlined"
                                            className={classes.button} color="primary"
                                            onClick={this.questionsCreate} >
                                            {labels.SEND_BUTTOM}
                                        </Button>
                                    </Grid>
                                    {localStorage.getItem('loginBool') &&
                                        <Grid xs={12} className={classes.recaptcha}>
                                            {this.state.captcha === false &&
                                                <Typography variant="subtitle2" className={classes.captcha}>{"Debe validar que no es un robot"}</Typography>
                                            }
                                            <ReCAPTCHA
                                                ref={this.captcha}
                                                sitekey={properties.RECAPTCHA_SITE_KEY}
                                                onChange={this.onChange}
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={12}>
                                        {questionsPayload && questionsPayload.map(quest => (
                                            <Paper component="form" elevation={0} className={classes.questions}>
                                                <Grid container className={classes.paddingDetail}>
                                                    <Grid xs={12}>
                                                        <Typography variant='body1' ><strong>{quest.nombre + " " + quest.apellido}</strong></Typography>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Typography variant='body2' >{quest.date}</Typography>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Typography variant='body2' className={classes.marginQuestions}>{quest.mensaje}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {quest.respuesta.length > 0 && quest.respuesta.map(resp => (
                                                    <Grid container style={{ marginTop: '-8px', textAlign: 'right', padding: 8 }}>
                                                        <Grid xs={12}>
                                                            <Typography variant='body1' color='primary'><strong>{resp.nombre + " " + resp.apellido}</strong></Typography>
                                                        </Grid>
                                                        <Grid xs={12}>
                                                            <Typography variant='body2' >{resp.date}</Typography>
                                                        </Grid>
                                                        <Grid xs={12}>
                                                            <Typography variant='body2' className={classes.marginQuestions}>{resp.mensaje}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Paper>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.similares}>
                            <Grid container className={classes.paddingDetail}>
                                <Grid xs={12}>
                                    <Typography variant='h5'>Productos similares</Typography>
                                    <ImagenesSliderSwiper type={1} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>
                    :
                    <div>
                        <Dialog
                            open={this.props.productDetails}
                            TransitionComponent={Transition}
                            fullWidth="true"
                            maxWidth="md"
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"

                        >
                            <Grid xs={12} container>
                                <Grid item xs={12} sm={4} md={4} className={classes.fondoDetailProduct}>
                                    <Grid container className={classes.paddingDetail} style={{
                                        minHeight: 400,
                                    }}>
                                        <Typography variant='h5' className={classes.hiddenMobile}>{name}
                                            <Divider color='primary' className={classes.divider} />
                                        </Typography>

                                        <Grid item xs={12} sm={12} md={12}
                                            className={classes.hiddenDesktop}>
                                            <Grid container >
                                                < Grid item xs={10} sm={11} md={11}>
                                                    <Typography variant='h4'>{name}
                                                        <Divider color='primary' className={classes.divider} />
                                                    </Typography>
                                                </Grid>
                                                < Grid item xs={2} sm={1} md={1} >
                                                    <IconButton position="static" onClick={this.handleClose}>
                                                        <i style={{ fontSize: 15, marginTop: -5 }} class="icon-guss_close"></i>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid container className={classes.contenedorOverflow}>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography variant='h6' color='secondary'>Información del producto</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography variant='subtitle1' className={classes.margin} >{desc}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container className={classes.contenedorColores}>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography variant='h6' color='secondary' className={classes.color} >Colores del producto </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12} className={classes.contenedorColoresItems}>
                                                    {Colores.map(col => (
                                                        <Grid xs={4} sm={3} md={3} style={{ marginRight: -15 }}>
                                                            <Grid container className={classes.color} >
                                                                <CardActionArea style={{ height: 35, width: 35 }} onClick={this.ColorsProduct.bind(this, col.cHexadecimal, col.cUrl)}>
                                                                    {col.cUrl ?
                                                                        <img src={col.cUrl} alt='img' className={classes.colors} />
                                                                        :
                                                                        <Grid style={{ backgroundColor: col.cHexadecimal, height: 35, width: 35 }} />
                                                                    }
                                                                </CardActionArea>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}
                                        container
                                        justify="center"
                                    >
                                        {this.state.activeBtnCar ?
                                            <Button style={{ backgroundColor: properties.primary, borderRadius: 0, marginBottom: 15 }}
                                                startIcon={<i style={{ fontSize: 20, color: properties.white }} class="icon-guss_shop"></i>}
                                                onClick={this.handleOpenAddCar}>
                                                <span style={{ color: properties.white }} >{labels.SHOP}</span>
                                            </Button>
                                            :
                                            <Button style={{ backgroundColor: properties.primary, borderRadius: 0, marginBottom: 15 }}
                                                startIcon={<i style={{ fontSize: 20, color: properties.white }} class="icon-guss_shop"></i>}
                                                onClick={this.isNotLoggedIn}>
                                                <span style={{ color: properties.white }} >{labels.SHOP}</span>
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className={classes.fondoProduct}>
                                    <Grid container>
                                        <Grid item xs={3} sm={1} md={1}>
                                            <Tooltip title={labels.LIKE_PR} placement="top">
                                                <Box position="static" onClick={this.icons.bind(this, 0)} style={{ textAlign: "center", cursor: "pointer" }}>
                                                    {activeLike
                                                        ? <img alt="img" src={LikeSi} style={{ marginTop: 11, marginLeft: 10 }} width={32} />
                                                        : <img alt="img" src={LikeNo} style={{ marginTop: 11, marginLeft: 10, color: "black" }} width={32} />
                                                    }
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3} sm={1} md={1}>
                                            <IconButton position="static" onClick={this.handleShare}>
                                                <i style={{ fontSize: 20, color: properties.green }} class="icon-guss_share"></i>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={3} sm={1} md={1}>
                                            <Tooltip title={labels.SAVE} placement="top">
                                                <Box position="static" onClick={this.icons.bind(this, 1)} style={{ textAlign: "center", cursor: "pointer" }}>
                                                    {activeSave
                                                        ? <img alt="img" src={SaveSi} style={{ marginTop: 12, marginLeft: 10 }} width={30} />
                                                        : <img alt="img" src={SaveNo} style={{ marginTop: 12, marginLeft: 10, color: "black" }} width={30} />
                                                    }
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3} sm={9} md={9}
                                            container
                                            justify="flex-end" className={classes.hiddenMobile}>
                                            <IconButton position="static" onClick={this.handleClose}>
                                                <i style={{ fontSize: 20 }} class="icon-guss_close"></i>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                                            <Grid item xs={12} sm={12} md={12}
                                                container
                                                justify="center" elevation={0} >
                                                <Magnifier src={imgURL} className={classes.imgDetail} mgBorderWidth={0} mgWidth={200} mgHeight={200} zoomFactor={0.7} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} container justify="flex-end" className={classes.priceDetail} elevation={0} >
                                                {Promo.length === 0 ?
                                                    <>
                                                        <Typography variant="h7" style={{
                                                            marginTop: 0, float: 'right',
                                                            color: properties.white
                                                        }}>${price}</Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={12} >
                                                            <Typography style={{ marginTop: 0, float: 'right', fontSize: 29, color: properties.white, }}>${Promo[0].valorPromo}</Typography><br />
                                                            <Typography style={{ marginTop: 0, float: 'right', fontSize: 20, color: properties.white, textDecoration: 'line-through' }}>${price}</Typography>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container
                                    alignItems="center"
                                    className={classes.fondoDescription}>

                                    <Grid item xs={2} sm={1} md={1}>
                                        <Button disabled={this.state.activeStep === 0}>
                                            <ArrowBackIcon className={classes.Arrow} onClick={this.handleBack.bind(this, false)} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8} sm={10} md={10}>
                                        <Grow in={this.state.animation}>
                                            <Grid container spacing={0}  >
                                                {product.length > 0 && product[this.state.activeStep].map(cat => (
                                                    <Grid item xs={12} sm={3} md={3}>
                                                        <CardActionArea style={{ padding: 10 }} onClick={this.imgDetail.bind(this, cat.imgURL)}>
                                                            <img alt="mueble" src={cat.imgURL} width="160" className={classes.imgDescription} />
                                                        </CardActionArea>
                                                    </Grid>
                                                ))}

                                            </Grid>
                                        </Grow>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={1} >
                                        <Button disabled={this.state.activeStep === (product.length - 1)}>
                                            <ArrowForwardIcon className={classes.Arrow} onClick={this.handleNext.bind(this, false)} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid container className={classes.paddingDetail}>
                                    <Grid xs={12} md={12} sm={12}>
                                        <Typography variant='h5'>Ficha técnica del producto</Typography>
                                    </Grid>
                                    <Grid xs={12} md={12} sm={12}>
                                        {FichaTecnica.length > 0 ?
                                            FichaTecnica.map(resp => (
                                                <Typography variant='subtitle1' className={classes.margin}>{resp.descripcion}</Typography>
                                            ))
                                            :
                                            <Typography variant='subtitle1' className={classes.margin}>No posee información.</Typography>
                                        }
                                    </Grid>
                                    <Grid xs={12} md={12} sm={12}>
                                        <Typography variant='subtitle1' className={classes.marginCategory}><strong>Categorías</strong>:</Typography>
                                    </Grid>
                                    <Grid xs={12} md={12} sm={12} className={classes.margin} style={{ display: 'inline-flex' }}>
                                        {Categorias.map(cat => (
                                            <Chip color={properties.gray} label={cat.descripcion} className={classes.chip} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} >
                                <Grid xs={12} md={12} sm={12} className={classes.answer}>
                                    <Typography variant='h5'>Preguntas y respuestas</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid container className={classes.fondoProductAnswer} >
                                    <Grid xs={12} md={10} sm={10} style={{ marginBottom: 10 }}>
                                        <Paper elevation={0}
                                            className={classes.root} >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="Ingrese una nueva pregunta"
                                                value={comment}
                                                type="text"
                                                onChange={this.handleInputChange}
                                                fullWidth
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid xs={12} md={2} sm={2}>
                                        <Button disabled={localStorage.getItem('loginBool') ? false : true}
                                            variant="outlined"
                                            className={classes.button} color="primary"
                                            onClick={this.questionsCreate} >
                                            {labels.SEND_BUTTOM}
                                        </Button>
                                    </Grid>
                                    {localStorage.getItem('loginBool') &&
                                        <Grid xs={12} md={12} sm={12} className={classes.recaptcha}>
                                            {this.state.captcha === false &&
                                                <Typography variant="subtitle2" className={classes.captcha}>{"Debe validar que no es un robot"}</Typography>
                                            }
                                            <ReCAPTCHA
                                                ref={this.captcha}
                                                sitekey={properties.RECAPTCHA_SITE_KEY}
                                                onChange={this.onChange}
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={12} md={12} sm={12}>
                                        {questionsPayload && questionsPayload.map(quest => (
                                            <Paper component="form" elevation={0} className={classes.questions}>
                                                <Grid container className={classes.paddingDetail}>
                                                    <Grid xs={12} md={12} sm={12} >
                                                        <Typography variant='body1' ><strong>{quest.nombre + " " + quest.apellido}</strong></Typography>
                                                    </Grid>
                                                    <Grid xs={12} md={12} sm={12}>
                                                        <Typography variant='body2' >{quest.date}</Typography>
                                                    </Grid>
                                                    <Grid xs={12} md={12} sm={12}>
                                                        <Typography variant='body2' className={classes.marginQuestions}>{quest.mensaje}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {quest.respuesta.length > 0 && quest.respuesta.map(resp => (
                                                    <Grid container className={classes.marginRespuesta}>
                                                        <Grid xs={12} md={12} sm={12} >
                                                            <Typography variant='body1' color='primary'><strong>{resp.nombre + " " + resp.apellido}</strong></Typography>
                                                        </Grid>
                                                        <Grid xs={12} md={12} sm={12}>
                                                            <Typography variant='body2' >{resp.date}</Typography>
                                                        </Grid>
                                                        <Grid xs={12} md={12} sm={12} >
                                                            <Typography variant='body2' className={classes.marginQuestions}>{resp.mensaje}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Paper>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Grid container className={classes.paddingDetail}>
                                    <Grid xs={12} md={12} sm={12}>
                                        <Typography variant='h5'>Productos similares</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container
                                    alignItems="center"
                                    className={classes.fondoProductSimilares}>

                                    <Grid item xs={2} sm={1} md={1}>
                                        <Button disabled={this.state.activeStepSimilares === 0}>
                                            <ArrowBackIcon className={classes.Arrow} onClick={this.handleBack.bind(this, true)} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8} sm={10} md={10}>
                                        <Grow in={this.state.animation1}>
                                            <Grid container spacing={0}  >
                                                {similares.length > 0 && similares[this.state.activeStepSimilares].map(cat => (
                                                    <Grid item xs={12} sm={3} md={3}>
                                                        <CardActionArea style={{ padding: 10 }} onClick={this.imgDetailSimilares.bind(this, cat.idArticulo)}>
                                                            <img alt="mueble" src={cat.url} width="160" className={classes.imgDescription} />
                                                        </CardActionArea>
                                                    </Grid>
                                                ))}

                                            </Grid>
                                        </Grow>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={1} >
                                        <Button disabled={this.state.activeStepSimilares === (similares.length - 1)}>
                                            <ArrowForwardIcon className={classes.Arrow} onClick={this.handleNext.bind(this, true)} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Dialog>
                    </div >
                }

                <Dialog
                    open={this.state.share}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {labels.SHARE_LINK}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container>
                                <Grid item xs={8} md={10} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    wordBreak: 'break-all',
                                }}>
                                    <Typography variant="caption">{this.state.url}</Typography>
                                </Grid>
                                <Grid item xs={2} md={1} style={{ paddingLeft: 10 }}>
                                    <FileCopyIcon
                                        className={classes.IconCopy}
                                        onClick={() => { this.handleCoppy(true) }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <WhatsAppIcon
                                        className={classes.IconCopy}
                                        onClick={this.handleWhatsappShare}
                                    />
                                </Grid>
                                <Snackbar open={this.state.coppied} autoHideDuration={1000} onClose={() => { this.setState({ coppied: false }) }}>
                                    <Alert variant="filled" severity="success">
                                        Texto copiado al portapeles.
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ share: false }) }} color="primary">Aceptar</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const Detail = withStyles(styles, { withTheme: true })(ProductDetail)
function mapStateToProps(state) {
    return {
        productDetails: state.products.productDetails,
        loader: state.loaderReducer.loader,
        preferencesUpdate: state.products.preferencesUpdate,
        questionsPayload: state.products.questionsPayload,
        openShoppingCar: state.purchase.openShoppingCar,
        moneda: state.homeProps.moneda,
        productsData: state.homeProps.productsData,
        isMobile: state.homeProps.isMobile
    }
}

export default connect(
    mapStateToProps, {
    detailProduct,
    getDetailProduct,
    postActualizarPreferencias,
    setLoader,
    postCreateQuestions,
    setAlert,
    openAddCarModal,
    searchCategories,
    imagenSelected,
    activeStep,
    loadingSearchMobile
})(Detail);